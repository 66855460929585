import React, { Fragment, useState } from 'react'
import logo from '../poa-logo-padded.png';
import {
    Bars3Icon,
    ArrowLeftIcon
} from '@heroicons/react/24/outline'
import {
    Transition,
    Dialog,
} from '@headlessui/react'

export default function Header(props) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="flex flex-row justify-between items-center text-center bg-[#000000] fixed top-0 left-0 w-full z-10 min-h-[75px] bg-opacity-65 backdrop-blur-[5px]">
                <div className='flex flex-row items-center max-w-[700px] w-full max-h-[75px]'>
                    <a href="https://www.oldottawa.com/" target="_blank" rel="noopener noreferrer">
                        <img src={logo} className='w-[75px]'/>
                    </a>
                </div>
                <div className="inset-y-0 right-0 flex items-center">
                    <Bars3Icon
                        className="block h-[72px] text-white cursor-pointer hover:text-[#FF7F3E] focus:outline-none focus:ring-0"
                        onClick={() => setOpen(true)}
                    />
                </div>
            </div>
            
            <Transition.Root show={open} as={Fragment} className=''>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-10 h-full" onClose={setOpen}>
                    {/* Overlay */}
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    {/* Sidebar Panel */}
                    <div className="fixed inset-0 h-full w-[80%] ml-auto">
                        <div className="absolute inset-0 h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0 translate-x-full"
                                enterTo="opacity-100 translate-x-0"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="opacity-0 translate-x-full"
                            >
                                <Dialog.Panel className="h-full w-full max-w-[413] absolute top-0">
                                <div className="h-full flex flex-col 
                                                justify-center items-center 
                                                bg-white shadow-xl overflow-y-hidden
                                                font-urw_bold text-lg tracking-custom 
                                                leading-custom py-[15px] gap-y-5">
                                    <button
                                        type="button"
                                        className='focus:ring-0'
                                        onClick={() => setOpen(false)}
                                    >
                                        <div className='flex flex-row justify-center items-center '>
                                            <ArrowLeftIcon className="block h-[20px] w-[20px] mr-[10px]"/>
                                            BACK
                                        </div>
                                    </button>
                                    <a href="https://www.oldottawa.com/">HOME</a>
                                    {/* Sidebar content */}
                                </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </>
    )
}
import React, { useRef, useState, useEffect } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css';
import Header from '../Components/Header';
import homepageLogo from '../homepage-full-logo.png'

function Homepage(props) {
    return (
        <>
        <Header />
        <div className="flex flex-col h-screen overflow-y-hidden bg-secondary">
            
            <div className="grid grid-cols-homepage h-screen bg-homepage-banner bg-cover">
            <div className='col-start-2 col-span-1 flex justify-center items-center'>
                <p className='grid grid-cols-homepage-logo w-full h-[450px] bg-white bg-opacity-80 backdrop-blur-[2px]'>
                    <img src={homepageLogo} className='w-full col-start-2 col-span-1'></img>
                </p>
            </div>
            </div>
        </div>
        </>
    )
}

export default Homepage
